<template>
  <ReportBase title="Mismatch Intents" :is-loading="loadingContent" class="mismatch-intents" infinite-scroll
    @loadMore="showMoreContent">
    <template #afterTitle>
      <div class="flex items-center ml-auto">
        <ModalBox title="Filters" button-name="filters" :loading="loadingContent" :showModal="showFilterModal"
          @showModal="showFilterModal = $event">
          <template #body>
            <label for="filterDate" class="text-xs font-600">period</label>
            <DatePickerRange id="filterDate" name="rangePicker" ref="datepicker" class="mt-2"
              :start-date="rangeDate.selected" :max-date="rangeDate.maxDate" :min-date="rangeDate.minDate"
              :preset-ranges="rangeDate.presetRanges" format="L" @apply="handleDateRangeUpdate" />
          </template>
        </ModalBox>
      </div>
    </template>

    <template #content>
      <div v-if="Object.keys(visibleConversations).length">
        <div v-for="conversationId in Object.keys(visibleConversations)" :key="conversationId"
          class="h-full flex flex-col mb-10">
          <div class="conversation-info">
            <div class="dialog-card">
              <p class="dialog-card__date">{{ visibleConversations[conversationId].date }}</p>
              <p class="dialog-card__text">Dialogflow session: <span
                  class="dialog-card__text dialog-card__text--highlight">{{ conversationId }}</span>
                <button class="dialog-card__copy-btn" @click="copyCode(conversationId)">
                  <Icon name="copy" />
                </button>
              </p>
            </div>
            <div class="table-header">
              <div v-for="column in visibleConversations[conversationId].columnDefs.filter(column => !column.hide)">
                <div :style="{ width: column.width + 'px' }" class="px-3 py-2 text-black">{{ column.headerName }}</div>
              </div>
            </div>
          </div>
          <div class="flex-1">
            <TableContent :columnDefs="visibleConversations[conversationId].columnDefs"
              :defaultColDef="visibleConversations[conversationId].defaultColDef"
              :rowData="visibleConversations[conversationId].rowData" domLayout="autoHeight" hide-header avoid-resize />
          </div>
        </div>
      </div>

      <div v-else>No content found.</div>
    </template>
  </ReportBase>
</template>

<script>
import moment from "moment";
import Icon from "@/components/ui/Icon";
import ModalBox from "@/components/report/common/ModalBox.vue";
import TableContent from '@/components/report/tabular/TableContent.vue';
import DatePickerRange from "@/components/ui/datepicker/DatePickerRange.vue";
import ReportBase from '@/components/report/tabular/ReportBase';
import NotifyMixin from '@/mixins/NotifyMixin';
import CubeRequestMixin from "@/components/report/common/CubeRequestMixin";
import { responseToTable, getKeyValue } from '@/components/report/tabular/cubeDataHandler';

export default {
  name: 'MismatchIntents',

  mixins: [NotifyMixin, CubeRequestMixin],

  components: {
    DatePickerRange,
    TableContent,
    ReportBase,
    ModalBox,
    Icon
  },

  data() {
    return {
      loadingContent: true,
      showFilterModal: false,
      rangeDate: {
        selected: [
          moment().subtract(7, "days").format("MM/DD/YYYY"),
          moment().format("MM/DD/YYYY"),
        ],
        maxDate: moment(),
        minDate: moment().subtract(20, "years"),
        presetRanges: [
          { label: "Today", range: [moment(), moment()] },
          {
            label: "Last week",
            range: [
              moment().subtract(1, "week").startOf("week"),
              moment().subtract(1, "week").endOf("week"),
            ],
          },
          {
            label: "This month",
            range: [moment().startOf("month"), moment()],
          },
          {
            label: "Last month",
            range: [
              moment().subtract(1, "month").startOf("month"),
              moment().subtract(1, "month").endOf("month"),
            ],
          },
          {
            label: "Last 30 days",
            range: [moment().subtract(30, "days"), moment()],
          },
          {
            label: "This quarter",
            range: [moment().startOf("quarter"), moment()],
          },
          {
            label: "Last quarter",
            range: [
              moment().subtract(1, "quarter").startOf("quarter"),
              moment().subtract(1, "quarter").endOf("quarter"),
            ],
          },
        ],
      },
      conversations: {},
      visibleOffset: 0,
      visibleConversations: {},
    }
  },

  computed: {
    selectedDateRange() {
      return this.rangeDate.selected;
    }
  },

  watch: {
    selectedDateRange(newDates) {
      this.fetchMismatchIntents(newDates)
    },
  },

  methods: {
    handleDateRangeUpdate(date) {
      this.showFilterModal = false;

      this.rangeDate.selected = date;
    },

    async fetchMismatchIntents(rangeDate) {
      this.loadingContent = true;

      try {
        const response = await this.fetchCubeData('DHR03', {
          startDate: rangeDate[0],
          endDate: rangeDate[1],
        });

        this.responseHandler(response);

        this.loadingContent = false;
      } catch (error) {
        this.notifyError(error.message);
      }
    },

    responseHandler(data) {
      // Clear current table status
      this.visibleOffset = 0;
      this.visibleConversations = {};
      this.conversations = {};

      const tableContent = responseToTable(data);

      tableContent.rowData.forEach(data => {
        const conversationId = getKeyValue(data, 'conversationsId');

        const columnData = this.conversations[conversationId];

        if (columnData) {
          // Add a now row to an existing table
          columnData.rowData.push(data)
        } else {
          // Create new table block
          this.conversations[conversationId] = {
            date: moment(getKeyValue(data, 'turnTimeDate'), 'YYYY-MM-DD').format('LL'),
            columnDefs: [...tableContent.columnDefs],
            defaultColDef: { ...tableContent.defaultColDef },
            rowData: [{
              ...data
            }]
          }
        }
      });

      this.showMoreContent();
    },

    copyCode(code) {
      navigator.clipboard.writeText(code);

      this.notifySuccess("Copied to clipboard");
    },

    showMoreContent() {
      const maxItemsPerLoad = 5;

      const lastItemToShow = this.visibleOffset + maxItemsPerLoad;

      const newItemsKeys = Object.keys(this.conversations).slice(this.visibleOffset, lastItemToShow);

      newItemsKeys.forEach(conversationKey => {
        const conversationContent = this.conversations[conversationKey];

        if (conversationContent) {
          Object.assign(this.visibleConversations, { [conversationKey]: conversationContent })
        }
      });

      this.visibleOffset = lastItemToShow - 1;
    },
  },

  mounted() {
    this.fetchMismatchIntents(this.selectedDateRange);
  },
};
</script>
<style scoped>
.mismatch-intents .dialog-card {
  @apply flex flex-col items-start bg-graphite-200 font-frank py-2 px-3 sticky;
}

.mismatch-intents .dialog-card__date {
  @apply text-xs font-500 mb-1;
}

.mismatch-intents .dialog-card__text {
  @apply font-500 text-graphite-900 flex items-center;

  font-size: 0.8125rem;
}

.mismatch-intents .dialog-card__text.dialog-card__text--highlight {
  @apply text-purple-350 pl-1;
}

.mismatch-intents .dialog-card__copy-btn {
  @apply pl-2;
}

.conversation-info {
  @apply sticky z-20 bg-white;

  top: -1px
}

.mismatch-intents .table-header {
  @apply flex items-center mt-2;

  font-size: 13px;
  box-shadow: inset 0px -1px 0px #161616;
}
</style>